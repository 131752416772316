// export const keynoteSpeakers = [
//   {
//     name: "Nelly Akoth",
//     image: require("./assets/headshots/Nelly_Akoth.jpg"),
//     title: "Senior Partner, Global Finance Practice Leader at IBM"
//   }
// ]

export const panelSpeakers = [
  {
    name: "Amarjot Kaur",
    image: require("./assets/headshots/22_Amarjot_Kaur_headshot.jpg"),
    title: "Executive Director, Merck",
    bio:
      "Amarjot is currently an Executive Director and Statistics Head of the Respiratory and Immunology therapeutic area at Merck Research Labs.Amarjot has been in the pharmaceutical industry for over 25 years, working across various aspects of drug development.Prior to joining Merck, she conducted active research on various EPA funded environmental sampling problems at Penn State University. She is also actively engaged in various statistical societies and served as the President of International Indian Statistical Association(IISA).She also served on the ASA Board of Directors as a Treasurer and is an elected Fellow of the ASA."
  },
  {
    name: "Anne Pluta",
    image: require("./assets/headshots/22_Anne_Pluta_headshot.jpg"),
    title: "Associate Professor of Political Science, Rowan University",
    bio:
      "Anne earned her Ph.D.in Political Science from the University of California, Santa Barbara.She is currently an Associate Professor in Rowan University’s Department of Political Science and Economics.Her primary research interests are American political institutions and American Political Development with a focus on gender.In addition, Anne is interested in the methodological challenges involved in doing historical research in political science.Her work has appeared in a number of peer reviewed journals including Presidential Studies Quarterly and Congress and the Presidency.Anne has two forthcoming books: Persuading the Public: The Evolution of Popular Presidential Communication from Washington to Trump(University Press of Kansas) and Work Like a Woman: Pre - Congressional Experience, Gender and Legislative Behavior with M.Knight Finley (New York University Press). Anne is also the founding direction of Rowan’s Center for Social Science Research."
  },
  {
    name: "Caroline Nganga",
    image: require("./assets/headshots/22_Caroline_Nganga_headshot.jpeg"),
    title: "Analytics Program Manager, Google",
    bio:
      "Caroline has focused her career on leveraging statistics and data analysis to influence business strategy.After getting a M.Stat from Rice University, she worked as a Statistician at Capital One before moving to Google in 2015. At Google she has worked as a Data Analyst and Program Manager across 3 products, including maps.Her current role is focused on building the data infrastructure and reporting for Google &#39; s User Research Infrastructure and Operations.When not working, Caroline enjoys cooking & amp; eating, reading, and exploring Switzerland, where she moved in 2021"
  },
  {
    name: "Flora Huang",
    image: require("./assets/headshots/22_Flora_Huang_headshot.jpeg"),
    title: "Investment Management FinTech Data Scientist, Vanguard",
    bio:
      "Flora Huang is a senior data scientist on the Investment Management Fintech Strategies(IMFS) team at Vanguard.She collaborates with cross - functional teams on end - to - end data science solutions, including the creation and integration of alternative data signals and machine learning models to Vanguard's investment processes, and the evaluation and adoption of emerging technologies. Flora graduated from Columbia University with a degree of MSc in Data Science in 2020. She majored in Quantitative Finance at National Tsing Hua University and Mathematics & amp; Statistics at National University of Singapore during her undergraduate studies."
  },
  {
    name: "Dr. Jeanne Ruane",
    image: require("./assets/headshots/22_Jeanne_Ruane_headshot.jpg"),
    title:
      "Executive Director, Temple's Data Science Institute & Assistant Professor of Statistical Science, Fox Business School",
    bio:
      "Regina(Jeanne) Ruane, Ph.D.is Executive Director of Temple University's Data Science Institute and an assistant professor of research in the Department of Statistical Science. Ruane joined the Department of Statistical Science following a research fellowship with Harvard University and Temple University, where she conducted research in data science and digital analytics with a focus on networks, information systems and statistics. Ruane designs and conducts analysis of digital experiments involving business analytics, network data, sampling, modeling and inference with social network data in complex observational studies with a time, space, and / or network component in financial technology (FinTech), crowdfunding, and emerging technologies; digital experiments involving network data; and sampling, modeling and inference with social network data.A number of Ruane's key research ideas have originated from collaborations with applied researchers in Tech, Finance, and government agencies, including Vanguard, Vertex, several startups, the City of Philadelphia, the Keystone Space Collaborative, among others. In her role with the DSI, she works closely with industry partners, including Wells Fargo, Google, Disney, and LinkedIn, to identify and address problems for which a solution would have a substantial impact in the world. Ruane earned her PhD from Drexel University and holds a MA from Columbia University and a BA from Villanova University.Ruane has worked in industry and higher education in a variety of roles with early-stage start-ups, established industry leaders, and government agencies.Her work has appeared in journals across Information Systems, Health, and Education, including Communications of the Association for Information Systems, Online Learning, where she received the Best Paper Award, Health Behavior and Policy Review, and Journal of Opioid Management."
  },
  {
    name: "Maja Vukovic",
    image: require("./assets/headshots/22_Maja_Vukovic_headshot.jpg"),
    title: "IBM Fellow",
    bio:
      "Maja Vuković is an IBM Fellow, responsible for technical and research strategy for AI driven Application Modernization. Maja is a member of IBM Academy of Technology and an IBM Master Inventor, with over 180 patents granted.She is a Senior Member of IEEE, and was awarded Women in Services Computing Award by IEEE.Maja has received her Ph.D.from the University of Cambridge, for her work on context - aware service composition using AI planning."
  },

  {
    name: "Dr. Kathey Ensoor",
    image: require("./assets/headshots/22_Kathey_Ensor_headshot.jpg"),
    title: "President of American Statistical Association",
    bio:
      "Katherine Bennett Ensor is the Noah G.Harding Professor of Statistics at Rice University where she serves as director of the Center for Computational Finance and Economic Systems(cofes.rice.edu) and creator of the Kinder Institute's Urban Data Platform(kinderudp.org).Ensor served as chair of the Department of Statistics from 1999 through 2013 and has shaped data science at Rice as a member of the campus wide hiring committee.Her research focuses on development of statistical and data science methods for practical problems.Her expertise is on dependent data covering time, space and dimension with applied interests in finance, energy, environment, health and risk management.She is a fellow of ASA and AAAS and has been recognized for her leadership, scholarship and mentoring.Ensor is the 2022 President of the American Statistical Association(ASA).She served as Vice President of ASA from 2016 - 2018 and as member of the National Academies Committee on Applied and Theoretical Statistics from 2014 - 2020. Ensor holds a BSE and MS in Mathematics from Arkansas State University and a PhD in Statistics from Texas A & amp;M University.Ensor is a member of Texas A & amp;M College of Science Academy of Distinguished Former Students."
  }
];

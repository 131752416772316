<template>
  <div>
    <v-app-bar fixed>
      <v-img
        v-if="mediumScreen"
        :src="require('../assets/wids-black-1line.png')"
        contain
        height="80"
        max-width="300"
      />
      <v-spacer />
      <v-row class="mr-12">
        <v-col>
          <ul class="nav">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#agenda">Agenda</a>
            </li>
            <!-- <li>
              <a href="#speakers">Speakers</a>
            </li>
            <li>
              <a href="#partners">Partners</a>
            </li> -->
          </ul>
        </v-col>
      </v-row>
    </v-app-bar>
    <section>
      <a name="home"></a>
      <v-parallax
        :src="require('../assets/bg_2024.jpg')"
        :height="mediumScreen ? 750 : 850"
      >
        <h1 class="text-xs-center" style="margin-top: 25px">
          Women in Data Science
          <span class="green--text text--darken-1">Philadelphia</span>
        </h1>
        <div
          class="text-xs-center subtitle white--text"
          style="{text-align: center, background-color: white , shadow-box: 1px solid}"
        >
          <!-- <h2>Stay tuned for more information about WiDS Philadelphia 2022!</h2> -->
          <h2>Conference Date: December 10th, 2024 8:00am - 4:30pm</h2>
          <h2>
            Venue: {{ locationAddress.line1 }}
            <br />
            {{ locationAddress.line2 }}
            <br />
            {{ locationAddress.line3 }}
          </h2>
          <h2>Registration now open for WiDS Philadelphia 2024!</h2>
          <!-- <p class="subtitle">
            To keep in touch, connect with us on the Women in Data Science
            Philadelphia LinkedIn Group. If you are interested in being a
            speaker or sponsor for WiDS Philadelphia 2023, please email
            <strong style="color: red">
              <address>
                <a class="white_link" href="mailto:widsphiladelphia@vgimfs.com"
                  >widsphiladelphia@vgimfs.com</a
                >
              </address>
            </strong>
          </p> -->
        </div>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                class="mb-2"
                outlined
                large
                block
                href="https://www.eventbrite.com/e/2024-wids-philadelphia-conference-thriving-in-the-ai-era-tickets-1068838788589?aff=oddtdtcreator"
                color="white"
                ><span style="{font-size: 25px}"
                  >Register for WIDS 2024 In-Person</span
                ></v-btn
              >
            </v-col>
            <v-col>
                <v-btn
                  class="mb-2"
                  outlined
                  large
                  block
                  href="https://www.eventbrite.com/e/2024-wids-philadelphia-conference-thriving-in-the-ai-era-virtual-tickets-1082758011359?utm_experiment=test_share_listing&aff=ebdsshios"
                  color="white"
                  ><span style="{font-size: 25px}"
                    >Register for WIDS 2024 Virtual</span
                  ></v-btn
                >
              </v-col>
          </v-row>

          <!-- <v-row v-if="mediumScreen">
            <v-col cols="6">
              <v-btn
                outlined
                large
                block
                href="https://www.youtube.com/playlist?list=PLcTh7H2gOc8brsUOrF6CDAGO_tVXbXaBW"
                color="white"
                >View WiDS Philadelphia 2021 Recordings</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                outlined
                large
                block
                href="https://www.linkedin.com/groups/13823400/"
                color="white"
                >Connect with us on Linkedin</v-btn
              >
            </v-col>
          </v-row> -->

          <!-- <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-btn
              class="mb-2"
              outlined
              large
              block
              href="https://www.youtube.com/playlist?list=PLcTh7H2gOc8brsUOrF6CDAGO_tVXbXaBW"
              color="white"
              >View WiDS Philadelphia 2021 Recordings</v-btn
            >
          </v-row>
          <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-btn
              class="mb-2"
              outlined
              large
              block
              href="https://www.linkedin.com/groups/13823400/"
              color="white"
              >Connect with us</v-btn
            >
          </v-row> -->

          <!-- <v-row style="text-align: center;">
            <v-col>
              <b class="subtitle-1 white--text">
                *All registration proceeds will go to
                <v-img
                  contain
                  src="../assets/CodedbyKids_logo.png"
                  height="30"
                />For more information, visit
                <a class="white_link" href="http://www.codedbykids.com/"
                  >their website.</a
                >
              </b>
            </v-col>
          </v-row> -->
        </v-container>
      </v-parallax>
    </section>

    <section>
      <a name="about"></a>
      <v-container my-5>
        <v-row>
          <v-col :cols="cardCols" style="margin: auto">
            <v-card color="#145C32" class="white--text">
              <v-card-title class="display-1" z
                >About WiDS Worldwide</v-card-title
              >
              <v-card-text>
                <div class="white--text" style="font-size: 22px">
                  <p>
                    The woman in Data Science Worldwide (WiDS) is an 
                    organization that seeks to elevate women in the field 
                    of data science by inspiring educating and supporting 
                    female data scientists. What started as a single conference 
                    at Stanford University in 2015 has expanded to a global 
                    organization with participation across six continents and a 
                    suite of programs that feature, connect, and highlight outstanding
                    women doing outstanding work. We envision a future in which women 
                    are fully integrated and represented in all areas of Data Science
                    and share equally in Decision Making, economic Prosperity, and Opportunities.
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :cols="cardCols">
            <v-card flat color="transparent" justify-center>
              <v-img
                contain
                src="../assets/wids_phil_2024.jpg"
                height="275"
              />
              <v-card-actions my-0 class="justify-center">
                <v-btn
                  icon
                  small
                  v-for="(item, index) of widsLinks"
                  v-bind:key="index"
                  :href="item.link"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-parallax
        :height="parallaxImageHeight"
        :src="require('../assets/philly_3.jpg')"
      >
        <v-row align="center" justify="center" no-gutters my-3>
          <div class="white--text infotext scrollable">
            <!-- <p>
              We are excited to invite individuals from diverse backgrounds,
              including those in industry and academia, to join us in building a
              vibrant data science community in Philadelphia. Our vision is to
              form a community for data scientists and technical professionals
              to connect and share their knowledge, fostering growth and
              development in the field. Our efforts aim to promote women in data
              science, encourage networking opportunities, and facilitate
              continuous learning. We warmly welcome you to be a part of our
              community.
              <strong>
                All genders are invited to attend WiDS regional events, which
                features outstanding women doing outstanding work.
              </strong>
            </p>
            <p>
              WiDS Philadelphia will be a great opportunity to connect with data
              science professionals, and features a program that includes
              technical talks, panel discussions, recruiting opportunities and
              several networking breaks throughout the day. Our event brings
              together local academic leaders, industry professionals and
              students, and we invite all genders to participate in WiDS
              regional events. We look forward to seeing you there!
            </p> -->
            <p>
              This year's conference, themed 'Thrive in the AI Era,' will take 
              place on December 10th. This one-day event will feature keynote speeches,
              research presentations, technical sessions, and panel discussions, bringing 
              together professionals and graduate students passionate about AI and machine learning. 
              We have an exciting lineup of speakers, including industry leaders, professors from top-tier 
              universities, and seasoned AI/ML practitioners.
            </p>
            <p>
              Our conference is free and open to all AI/ML enthusiasts, with our 
              speakers generously volunteering their time and expertise. This allows 
              us to foster diversity, equity, and inclusion, particularly for female 
              technologists, and give back to the broader AI/ML community.
            </p>
          </div>
        </v-row>
      </v-parallax>
    </section>
    <section style="background: #424242">
      <a name="agenda"></a>
      <v-container>
        <h1 class="text-xs-center my-5">Agenda</h1>
        <Timeline></Timeline>
        <h3 class="text-xs-center mb-1">
          BONUS SESSION! Generative AI Webinar: Fine Tuning Gemini in AI Studio and Vertex AI
          <br/>
          with Roya Kandalan, PhD, AI Research Scientist | December 12 @ 1:00 - 2:30p
        </h3>
        <h3 class="font-weight-light white--text">
          Learn advanced fine-tuning techniques using Google AI Studio and Vertex AI to
          <br/>
          enhance the performance and accuracy of large language model
        </h3>
      </v-container>
    </section>

    <!-- TODO Add Back Later when we have speaker images -->
    <!-- <section>
      <a name="speakers"></a>
      <v-container grid-list-xl>
        <h1 class="text-xs-center black--text my-5">Speakers</h1>
        <v-row align="center" justify="center" class="mb-1">
          <v-col
            v-for="(speaker, index) of keynoteSpeakers"
            v-bind:key="index"
            cols="12"
            sm="4"
            md="3"
            my-1
          >
            <SpeakerImage :speaker="speaker" :width="250"></SpeakerImage>
          </v-col>
        </v-row>
        <v-divider class="mb-3" /> 

        <v-row dense align="center" justify="center" class="mb-1">
          <v-col
            v-for="(speaker, index) of panelSpeakers"
            v-bind:key="index"
            cols="12"
            sm="4"
            md="3"
          >
            <SpeakerImage :speaker="speaker" :width="200"></SpeakerImage>
          </v-col>
        </v-row>
      </v-container>
    </section> -->

    <section style="{padding: 25px}">
      <a name="participants"></a>
      <v-container grid-list-xl class="mb-7">
        <h1 class="text-xs-center black--text my-5">This Conference Was Made Possible By:</h1>
        <v-row align="center" justify="center" class="mb-1">
          <v-col
            v-for="(src, index) in corporateSponsorImages"
            v-bind:key="index"
            cols="12"
            sm="6"
            md="4"
            my-1
          >
            <v-img :src="src" />
          </v-col>
        </v-row>
        <!-- <v-divider class="mb-3" /> -->
        <!-- <v-row align="center" justify="center" class="mb-1">
          <v-col
            v-for="(src, index) in uniSponsorImages"
            v-bind:key="index"
            cols="12"
            sm="6"
            md="4"
            my-1
          >
            <v-img :src="src" />
          </v-col>
        </v-row> -->
      </v-container>
    </section>

    <!-- <section style="background: #ececec" height="800" class="mb-10 pb-10">
      <a name="involved"></a>
      <v-container grid-list-xl>
        <h1 class="text-xs-center black--text my-5">Get Involved</h1>
        <v-row>
          <v-card color="blue" class="white--text">
            <v-card-title class="display-1" justify="center">
              Student Poster Submission
            </v-card-title>

            <v-card-text>
              <div class="white--text" style="font-size: 22px">
                <p>
                  Are you a student interested in showcasing your work? WiDS
                  Philadelphia will host a mid-day Student Poster Session as
                  part of its April 4th virtual conference. The Student Poster
                  Session will give students a chance to share their data
                  science-related research and projects, receive feedback, and
                  network with data scientists in the Philadelphia region. All
                  student presenters must identify as a woman to align with the
                  WiDS Worldwide guidelines.
                </p>
                <b
                  >Please submit your posters and abstracts to
                  <strong>
                    <address>
                      <a
                        class="black_link"
                        href="mailto:widsphiladelphia@vgimfs.com"
                        >widsphiladelphia@vgimfs.com</a
                      >
                    </address>
                  </strong></b
                >
              </div>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row style="text-align: center" align="center" justify="center">
          <v-col cols="12" sm="6" md="4" my-1>
            <p class="font-weight-bold button_label">
              Join our LinkedIn Group:
            </p>
            <v-btn
              href="https://www.linkedin.com/groups/13823400/"
              color="secondary"
              elevation="11"
              x-large
              tile
              target="_blank"
              >Join</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" md="4" my-1>
            <p class="font-weight-bold button_label">
              Find a local WIDS Community:
            </p>
            <v-btn
              href="https://www.womenindata.org/join-us"
              color="secondary"
              elevation="11"
              x-large
              tile
              target="_blank"
              >Find</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </section> -->

    <!-- <v-parallax height="800" :src="require('../assets/quorum.png')">
      <a name="register"></a>
      <v-container>
        <h1 class="text-xs-center my-5">Register</h1>
        <v-layout column align-center justify-center my-3>
          <div
            class="headline text-xs-center white--text"
            style="text-align: center"
          >
            {{ locationAddress.line1 }}
            <br />
            {{ locationAddress.line2 }}
            <br />
            {{ locationAddress.line3 }}
          </div>
          <br />
          <iframe
            v-if="mobileScreen"
            class="mb-5"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1529.158181399081!2d-75.195219!3d39.956679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c656cebe36a3%3A0x6b50ac518b4dbd49!2s3675%20Market%20St%2C%20Philadelphia%2C%20PA%2019104!5e0!3m2!1sen!2sus!4v1570714279488!5m2!1sen!2sus"
            width="375"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen
          ></iframe>
          <iframe
            v-else
            class="mb-5"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d764.5790862274936!2d-75.1957662!3d39.9566794!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c656cebe36a3%3A0x6b50ac518b4dbd49!2s3675%20Market%20St%2C%20Philadelphia%2C%20PA%2019104!5e0!3m2!1sen!2sus!4v1569517520115!5m2!1sen!2sus"
            width="600"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen
          ></iframe>
        </v-layout>
      </v-container>
    </v-parallax> -->

    <v-footer fixed>
      For more info —
      <strong>
        <address>
          <a class="black_link" href="mailto:VanguardWiDS@vanguard.com"
            >VanguardWiDS@vanguard.com</a
          >
        </address>
      </strong>
      <v-spacer />
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          icon
          small
          class="mx-2"
          v-for="(item, index) of widsLinks"
          v-bind:key="index"
          :href="item.link"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </div>
    </v-footer>
    <!--<v-footer color="transparent" fixed>
       TODO: remove this once coronavirus is gone :(
      <v-row align="center" justify="center">
        <v-alert
          class="title"
          type="warning"
          color="red"
          border="left"
          prominent
          dismissible
        >
          NEWS: WiDS Philadelphia 2020 postponed due to growing concerns over
          COVID-19 virus
        </v-alert>
      </v-row>
    </v-footer> --->
  </div>
</template>

<script>
import Timeline from "./Timeline";
// import SpeakerImage from "./SpeakerImage";
import {
  widsLinks,
  corporateSponsorImages,
  uniSponsorImages,
  registerLink,
  eventDate,
  locationAddress
} from "../eventInfo.js";
// import { keynoteSpeakers, panelSpeakers } from "../speakerInfo.js";
import { panelSpeakers } from "../speakerInfo.js";
export default {
  data() {
    return {
      widsLinks: widsLinks,
      registerLink: registerLink,
      corporateSponsorImages: corporateSponsorImages,
      uniSponsorImages: uniSponsorImages,
      eventDate: eventDate,
      locationAddress: locationAddress,
      // keynoteSpeakers: keynoteSpeakers,
      panelSpeakers: panelSpeakers
    };
  },
  computed: {
    cardCols() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 12;
      }
      return 6;
    },
    parallaxImageHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "700";
      }
      return "600";
    },
    mobileScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    mediumScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  components: {
    // SpeakerImage,
    Timeline
  }
};
</script>

<style scoped lang="scss">
// V-card text breaks halfway through words on this version of vuetify so this is necessary
.v-card__text,
.v-card__title {
  word-break: normal;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.scrollable {
  max-height: 700px;
  overflow-y: auto;
}
.infotext {
  width: 75%;
  font-size: 24px;
}
h1 {
  text-align: center;
  font-size: 70px;
  font-weight: 800;
  font-family: Roboto, Impact, Charcoal, sans-serif;
  color: white;
}
h2 {
  font-size: 25px;
}
h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, Impact, Charcoal, sans-serif;
  color: white;
}

// Links
.black_link:link {
  color: black;
}
.black_link:visited {
  color: black;
}
.black_link:hover {
  color: blue;
}
.white_link:link {
  color: white;
}
.white_link:visited {
  color: white;
}
.white_link:hover {
  color: blue;
}

.button_label {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, Impact, Charcoal, sans-serif;
}

// Header
.container {
  position: relative;
  width: 1170px;
  margin: 0 auto;
  color: #444;
  font-size: 14px;
  font-weight: 300;
  font-family: Roboto, "Open Sans", Arial, sans-serif;
  overflow: hidden;
}
.hold {
  height: 40px;
}
.header {
  line-height: 40px;
  width: 100%;
  transition: line-height 0.2s linear, box-shadow 0.2s linear;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(245, 245, 245, 0.97);
}
.header.small {
  line-height: 30px;
  box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.8);
}
ul.nav {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0 !important;
}
ul.nav li {
  float: left;
  position: relative;
}
ul.nav li a {
  transition: color 0.2s linear;
  font-size: 18px;
}
ul.nav li:hover a {
  color: red;
}
ul.nav li a {
  padding: 21px !important;
  color: initial;
  text-decoration: initial;
}

.subtitle {
  width: 80%;
  text-align: center;
  // margin: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

@media all and (max-width: 768px) {
  h1 {
    font-size: 40px;
    font-weight: 800;
    font-family: Roboto, Impact, Charcoal, sans-serif;
    color: white;
  }
  .container {
    width: 95%;
  }
  .header {
    height: auto;
    background: #eee;
  }
  .infotext {
    width: 90%;
    font-size: 16px;
  }
  ul.nav {
    float: none;
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  ul.nav li {
    float: initial;
    display: inline-block;
  }
  ul.nav li a {
    transition: color 0.2s linear;
    font-size: 12px;
  }
}
@media all and (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media all and (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media all and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
</style>
